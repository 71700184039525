<template>
  <svg
    width="36"
    height="35"
    viewBox="0 0 36 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.914307 3.96988C0.914307 1.84371 2.65389 0.104126 4.78006 0.104126C4.78006 0.104126 9.93439 0.104126 20.2431 0.104126V3.96988H4.78006V31.0301H20.2431V34.8959C9.93439 34.8959 4.78006 34.8959 4.78006 34.8959C2.65389 34.8959 0.914307 33.1563 0.914307 31.0301C0.914307 12.9067 0.914307 3.96988 0.914307 3.96988ZM28.3143 15.5671L23.4135 10.6663L26.147 7.93276L35.7142 17.5L26.147 27.0672L23.4135 24.3337L28.3143 19.4329H15.5848V15.5671H28.3143Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconExit",
  components: {},

  data: () => {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
