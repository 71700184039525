
<template>
  <section class="patient-terms">
    <h1 class="patient-terms__title">Termos de uso</h1>
    <iframe v-if="consentForm" :src="pdfUrl" frameborder="0"></iframe>
    <div v-else class="patient-terms__content" v-html="currentTermsOfUse" />
  </section>
</template>

<script>
import { html as htmlMedicar } from "@/views/paciente/components/header/termoMedicar.js";
import { html as htmlAxia } from "@/views/paciente/components/header/termoAxia.js";
import { html as htmlAma } from "@/views/paciente/components/header/termoAma.js";
import { html as htmlDurga } from "@/views/paciente/components/header/termoDurga.js";
import { html as htmlEmercor } from "@/views/paciente/components/header/termoEmercor";
import { html as htmlTelehelp } from "@/views/paciente/components/header/termoTelehelp";
import { html as htmlHomeangels } from "@/views/paciente/components/header/termoHomeangels";
import { html as htmlGeneric } from "@/views/paciente/components/header/termoGeneric.js";

export default {
  name: "PatientTerms",
  components: {
  },

  data() {
    return {
    };
  },
  computed: {
    currentClient () {
      return this.$route.query.cliente ?? process.env.VUE_APP_THEME
    },
    currentTermsOfUse () {
      switch(this.currentClient.toLocaleUpperCase()) {
        case 'MEDICAR': return htmlMedicar
        case 'AMA': return htmlAma
        case 'AXIA': return htmlAxia
        case 'EMERCOR': return htmlEmercor
        case 'DURGA': return htmlDurga
        case 'TELEHELP': return htmlTelehelp
        case 'HOMEANGELS': return htmlHomeangels
        default: return htmlGeneric
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.patient-terms {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  color: #333;
}
.patient-terms_title {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.patient-terms__content {
  align-self: center;
  max-width: 90%;
}
.patient-terms {
  flex: 1;
  display: flex;
  flex-direction: column;
}
iframe {
  border: none;
  width: 100%;
  height: 100%;
}
</style>

