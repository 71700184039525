<style src="./AlertMediaDevicePermissions.scss" lang="scss"></style>

<template>
  <div class="alert" :class="size" v-show="userHasNotMediaPermission">
    <component :is="icon"  />
    <span><strong>Atenção!</strong> {{ msgAlert }}</span>
    <button
      v-if="hasButton"
      class="button__permission"
      @click.stop="handleRequestPermissions"
    >Liberar Permissões</button>
  </div>
</template>

<script>
import AlertIcon from './alert-icon.vue'
import AlertBigIcon from './alert-big-icon.vue'
import MediaDeviceMixin from '@/mixin/MediaDeviceMixin';

export default {
  name: 'AlertMediaDevicePermissions',
  mixins: [MediaDeviceMixin],
  components: {
    AlertIcon,
    AlertBigIcon
  },

  props: {
    hasButton: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: () => 'small',
      validator(value) {
        return ['small', 'big'].includes(value)
      }
    }
  },

  data() {
    return {
      // msgAlert: 'É necessário liberar as permissões de áudio e vídeo.'
    }
  },

  computed: {
    icon() {
      return this.size === 'big' ? AlertBigIcon : AlertIcon
    },
    msgAlert () {
      if (!this.hasCameraPermission && !this.hasMicrophonePermision) {
        return 'É necessário liberar as permissões de microfone e câmera.'
      }
      if (!this.hasCameraPermission) {
        return 'É necessário liberar as permissões de câmera.'
      }
      if (!this.hasMicrophonePermision) {
        return 'É necessário liberar as permissões de microfone.'
      }
      return 'É necessário liberar as permissões de áudio e vídeo.'
    }
  },

  mounted() {
    this.processPermissions();
  },
  methods: {
    /**
     * Lida com a solicitação de permissões para câmera e microfone.
     * - Verifica se o navegador suporta `getUserMedia`.
     * - Consulta o estado das permissões do usuário para câmera e microfone.
     * - Exibe uma mensagem de erro se as permissões forem negadas.
     * - Solicita as permissões de mídia se todas estiverem liberadas.
     * @async
     * @returns {Promise<void>}
     * Retorna se as permissões estiverem configuradas corretamente ou
     * exibe alertas/erros em caso contrário.
     */
    async handleRequestPermissions() {
      if (!navigator?.mediaDevices?.getUserMedia) {
        return alert('Atenção, o navegador não suporta câmera e microfone!');
      }

      let permissaoCameraNegada = false;
      let permissaoMicrofoneNegada = false;
      // Verifica o estado da permissão para câmera
      const resultadoCamera = await navigator.permissions.query({ name: 'camera' });
      if (resultadoCamera.state === 'denied') {
        permissaoCameraNegada = true;
      }
      // Verifica o estado da permissão para microfone
      const resultadoMicrofone = await navigator.permissions.query({ name: 'microphone' });
      if (resultadoMicrofone.state === 'denied') {
        permissaoMicrofoneNegada = true;
      }
      // Notifica o usuário caso a permissão da câmera seja negada
      if (permissaoCameraNegada) {
        this.$toast.error(
          'Permissão de câmera negada. Verifique as configurações do navegador e habilite-a para esta página.',
          {
            duration: 5000,
          }
        );
      }
      // Notifica o usuário caso a permissão do microfone seja negada
      if (permissaoMicrofoneNegada) {
        this.$toast.error(
          'Permissão de microfone negada. Verifique as configurações do navegador e habilite-a para esta página.',
          {
            duration: 5000,
          }
        );
      }

      // Se ambas as permissões forem negadas, interrompe o processo
      if (permissaoCameraNegada || permissaoMicrofoneNegada) {
        return;
      }

      // Solicita as permissões de mídia
      await this.requestUserMediaPermission();
    }
  }
}
</script>
