import { render, staticRenderFns } from "./PatientTerms.vue?vue&type=template&id=ec2fdbcc&scoped=true"
import script from "./PatientTerms.vue?vue&type=script&lang=js"
export * from "./PatientTerms.vue?vue&type=script&lang=js"
import style0 from "./PatientTerms.vue?vue&type=style&index=0&id=ec2fdbcc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec2fdbcc",
  null
  
)

export default component.exports