import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ApplicationMixin from './mixin/ApplicationMixin'
import MediaDeviceMixin from './mixin/MediaDeviceMixin'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(VueToast, {
  position: 'bottom-right',
  duration: 3000
})

Vue.use(Vue2TouchEvents)

Vue.config.productionTip = false

Vue.mixin(ApplicationMixin)
Vue.mixin(MediaDeviceMixin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
