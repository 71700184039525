<template>
  <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M25.5 19.125V29.75" stroke="#DB3768" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M25.5004 45.4962H12.6228C5.24903 45.4962 2.16778 40.2262 5.73778 33.7874L12.3678 21.8449L18.6153 10.625C22.3979 3.80372 28.6029 3.80372 32.3854 10.625L38.6329 21.8662L45.2629 33.8087C48.8329 40.2474 45.7304 45.5174 38.3779 45.5174H25.5004V45.4962Z" stroke="#DB3768" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M25.4883 36.125H25.5074" stroke="#DB3768" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "AlertBigIcon",
  components: {},

  data: () => {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
